/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                var viewportWidth = $(window).width();

                // Fastclick
                FastClick.attach(document.body);

                // Remove empty p tags
                $('p:empty').remove();

                // External links
                $('a').filter(function(){
                    return this.hostname && this.hostname !== location.hostname;
                }).attr('target', '_blank');

                // Navigation Dropdown
                $('.js-toggle-dropdown').click(function() {
                    $(this).parent().toggleClass('is-expanded').siblings().removeClass('is-expanded');
                });

                // Navigation
                $('.c-toggle-nav').click(function(e) {
                    e.preventDefault();

                    $(this).toggleClass('is-open');

                    $('.c-nav').slideToggle();
                });

                // Lightbox - Images
                if (viewportWidth >= 1024) {
                    // Fancybox
                    $('.js-lightbox-image').attr('data-fancybox', 'group').fancybox();
                }

                // Lazy load images
                $('.js-unveil').unveil(200);

                // Carousel(s)
                $('.js-hero-carousel-home').slick({
                    autoplay: true,
                    autoplaySpeed: 5000,
                    arrows: false,
                    dots: true,
                    pauseOnHover: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                });

                if (viewportWidth < 1024) {
                    $('.js-posts-carousel, .js-events-carousel').slick({
                        autoplay: true,
                        autoplaySpeed: 4000,
                        arrows: false,
                        dots: true,
                        pauseOnHover: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    });
                }

                // Site Scrolling
                $(window).scroll(function() {
                    var scrollY = $(this).scrollTop();

                    if (scrollY > 300) {
                        $('.js-scroll-up').addClass('is-active');
                    } else {
                        $('.js-scroll-up').removeClass('is-active');
                    }
                });

                // Filter(s)
                if ($('.c-filter--desktop').length) {
                    $('.c-filter__item a').click(function(e) {
                        e.preventDefault();

                        var filterType = $(this).data('filter');

                        $('.c-business-type').hide();

                        $('.c-business-type[data-business="'+filterType+'"]').show();
                    });
                }

                if ($('.c-filter--mobile'.length)) {
                    $('.c-filter--mobile').on('change', function() {
                        var filterType = $(this).val();

                        if (filterType !== 'Select Business Type') {
                            $('.c-business-type').hide();

                            $('.c-business-type[data-business="'+filterType+'"]').show();
                        } else {
                            $('.c-business-type').show();
                        }
                    });
                }

                // Scroll Back Top
                $('.js-scroll-up').click(function(e) {
                    $('html, body').animate({
                        scrollTop: 0
                    }, 1000);
                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
